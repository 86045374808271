@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&family=Dosis:wght@200..800&family=Fredoka:wght@300..700&family=Jost:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');    
:root {
  --primary: #731414;
  --light: #F5F5F5;
  --dark: #353535;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-rgb: 115, 20, 20;
  --bs-emphasis-color-rgb: 115, 20, 20;
  --bs-secondary-rgb:  115, 20, 20,;
  --bs-tertiary-bg: #731414;
 
  
  
}
body{
  font-family: "Roboto", sans-serif;
}
.border-secondary{ 
   border-color: #fff!important;}
.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #731414;
  --bs-btn-border-color: #731414;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #731414;
  --bs-btn-hover-border-color: #731414;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #731414;
  --bs-btn-active-border-color: #731414;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #731414;
  --bs-btn-disabled-border-color: #731414;
}
.navbar {
  --bs-navbar-nav-link-padding-x: 1.0rem;
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-font-size: 0.9rem;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
  font-weight: bold;
}
.dropdown-menu {
  --bs-dropdown-link-active-color:#fff;
  --bs-dropdown-link-color:#404040;
  --bs-dropdown-link-active-bg: #731414;
  --bs-dropdown-link-hover-color:#fff;
  
}
.bg-dark {
  --bs-bg-opacity: 1;
  background-color:var(--primary) !important;
}

.fs-7 {
  font-size: 0.8rem !important;
}
.fs-8 {
  font-size: 0.63rem !important;
}
.bg-yellow{
  background-color:#FFDB00;
}
.text-yellow{
  color:#FFDB00!important;
}
.fw-medium {
  font-weight: 500 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-black {
  font-weight: 900 !important;
}
.content{
  color: #FFDB00;
  font-size: 2.8rem;
}
p{text-align: justify!important;}
p.text-justify-normal{text-align:left!important;}
hr {
  margin: 1rem auto; 
  color: #FFDB00;
  border: none;  
  border-top: 3px solid #FFDB00;  
  width: 50%;  
}
.owl-theme .item_1 img{
  width:250px;
  height:90px;

}
.customMargin{
  margin-top: 3rem;
  padding: 4.5rem;
  padding-left: 6.0rem;
  padding-bottom: 0;
  padding-top: 7%;
  text-align: center;
}
.background{
  background-image: url(../../sree-ramakrishna-trust/src/assets/img/background\ motif\ 1.png);     
  background-position: center; 
  background-repeat: no-repeat;               
  background-size: contain;
  
}
.home-footer { 
  background-size: cover !important;
  background-repeat: no-repeat !important;
  
}
.boximage{
  object-fit: cover;
}
.normal {
  color: #000;
  border: none;  
  border-top: 3px solid #000000;  
  width: 100%;  
}
/* Partner.css */
.slider {
  position: relative;
  overflow: hidden;
}

.image-slider {
  display: flex;
  overflow: hidden;
  width: 100%;
}

.image-slider-inner {
  display: flex;
  transition: transform 1s ease;
  width: 100%;
}

.item {
  flex: 1 0 20%; /* Adjust size of images */
  margin: 0 0.5rem; /* Adjust spacing between images */
  max-width: 100%;
}

.dots-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.dot {
  width: 8px;
  height:8px;
  border-radius: 50%;
  background-color: #bbb;
  margin-top: 50px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: #717171;
}
/* .carousel{
  margin-top:150px!important;
} */


.item {
  flex: 1 0 20%; /* Adjust size of images */
  margin: 0 0.5rem; /* Adjust spacing between images */
  max-width: 100%;
}
.homeContent h6{
  color: rgba(var(--bs-secondary-rgb)1)!important;
}
.homeContent h5{
  color: rgba(var(--bs-secondary-rgb)1)!important;
}
.aboutContent h4{
  color: rgba(var(--bs-secondary-rgb)1)!important;
  
}
.athirudram h4{
  color: rgba(var(--bs-secondary-rgb)1)!important;
  
}
h4{
  color: rgba(var(--bs-secondary-rgb)1)!important;
  
}
h4 a{
  color: rgba(var(--bs-secondary-rgb)1)!important;
  
}

.gallery-container {
  display: flex;
  width: 80%;
}

.sidebar {
  padding: 5px;
  background-color: #f0f0f0;
  float: left;
}

.sidebar h3 {
  margin-bottom: 10px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  cursor: pointer;
  padding: 5px 0;
  width:100%;
}

.sidebar li:hover {
  color: blue;
}

.gallery-grid {
  display: grid;
  
  gap: 10px; 
  width: 80%;
}

.gallery-item {
  width: 100%;
  height: 100%;
  overflow: hidden;
}


.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.lightbox-content {
  position: relative;
  max-width: 80%;
}

.lightbox-content img {
  width: 100%;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 30px;
  cursor: pointer;
}

.galleyimg-fluid {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures images fill the grid cell without distortion */
}
.galleryLinkActive{
   background-color: #731414;
   color:#fff;
   padding:5px!important;
}
.galleryLinkInactive{
  background-color: #fff;
  color:#404040;
  padding:5px!important;
}
.galleryLinkInActive:hover{
  color: #fff !important;
}
.galleryLinkInActive:hover{
  color: #404040 !important;
}
.sidebar li.galleryLinkActive:hover {
  color: #fff;
}
.sidebar li.galleryLinkInActive:hover {
  color: #404040;
}
@media (max-width: 767.98px) {
  .responsive {
    width: 50%; /* Makes the image take the full width of its container */
    height: auto; /* Maintains aspect ratio */
  }
}
.sticky-top {
  position: sticky;
 
  z-index: 1000!important;
}
.navbar-brand h5 {
  font-size: 1.25rem; /* Adjust as per screen size */
}
.navbar-brand img {
  max-width: 120px;
  height: auto; /* Maintain aspect ratio */
}

@media (max-width: 768px) {
  .navbar-brand h5 {
    font-size: 1.0rem;
  }
  .navbar-brand img {
      max-width: 75px;
  }
  .navbar-nav .nav-item {
      font-size: 0.9rem;
  }
  .customMargin {
    text-align: center;
    margin-top: 2.5rem;
    padding: 0.5rem;
    /* padding-left: 6.5rem; */
    padding-bottom: 0;
    padding-top: 0%;
  }
}



  .team-item {
    position: relative;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    text-align: center;
    height: 250px;
  }

  .team-item:hover {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  }

  .team-item img {
    width: 160px;   
    margin: 20px auto;
    transition: transform 0.5s;
  }

  .team-item:hover img {
    transform: scale(1.05);
  }

  .team-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(0);
    transition: height 0.5s ease;
    z-index: 1;
  }

  .team-item:hover .team-overlay {
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }

  .team-name {
    color: #731414;
    font-size: 1.2rem;
    margin: 10px 0;
    margin-top:42px;
    transition: color 0.3s ease;
  } 
  .team-name a{
     text-decoration: none;
     color:#731414;
  }
  .team-item:hover .team-overlay h5 a {
    color: #fff;
  }

  .btn-link-icon {
    color: #731414;
    font-size: 1.5rem;
    margin-top: 10px;
    transition: color 0.3s ease;
  }

  .btn-link-icon:hover {
    color: #fff;
  }
  .roboto-thin {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .roboto-light {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .roboto-regular {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .roboto-medium {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .roboto-bold {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .roboto-black {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: normal;
  }
  
  .roboto-thin-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: italic;
  }
  
  .roboto-light-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .roboto-regular-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .roboto-medium-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: italic;
  }
  
  .roboto-bold-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  
  .roboto-black-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: italic;
  }
 /* Remove border-right for small (sm) and extra small (xs) screens */
 @media (max-width: 767.98px) {
  .no-border-sm-xs {
    border-right: none !important;
  }
}

/* Add border-right for medium (md) and larger screens */
@media (min-width: 768px) {
  .no-border-sm-xs {
    border-right: 1px solid #fff;
  }
}