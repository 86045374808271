$outline-color_1: transparent;

.content-wrapper {
	background: #F4F5F7;
	padding: 1.5rem 2.187rem 1.5rem 3.5rem;
	width: 100%;
	-webkit-flex-grow: 1;
	flex-grow: 1;
}
.page-body-wrapper {
	min-height: calc(100vh - 97px);
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	padding-left: 0;
	padding-right: 0;
	padding-top: 97px;
}
.page-body-wrapper.full-page-wrapper {
	width: 100%;
	min-height: 100vh;
	padding-top: 0;
}
.auth {
	form {
		.form-group {
			.form-control {
				background: transparent;
				border-radius: 0;
				font-size: .9375rem;
			}
			margin-bottom: 1.5rem;
		}
	}
	.auth-form-light {
		background: #ffffff;
	}
}
input {
	outline: none;
	outline-width: 0;
	outline-color: $outline-color_1;
	box-shadow: none;
	outline-style: none;
	&:focus {
		outline: none;
		outline-width: 0;
		outline-color: $outline-color_1;
		box-shadow: none;
		outline-style: none;
	}
}
.form-control {
	&:focus {
		outline: none;
		outline-width: 0;
		outline-color: $outline-color_1;
		box-shadow: none;
		outline-style: none;
	}
}
.sidebar a.nav-link.active {
    background-color: #824012;
    color: #ccc;
}